<template>
  <div>
    <subscribe v-if="showPlansPopup" :subscribePayload="subscribepayload" :closePopup="() => toggleSubscribePopup(false)" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import Utility from "@/mixins/Utility.js";

export default {

  data() {
    return {
      subscribepayload: null,
      showPlansPopup: false,
      couponCode : null,
      planid : null
    };
  },

  computed: {
      ...mapGetters(["subscriberid"]),
  },

mounted() {
    this.subscribeMetaData();
    this.showPlansPopup = true;

    this.planid = this.$route.params.planId;
    this.couponCode = this.$route.params.coupon;

    let payload = {
      listPlans: "ALL",
      listType: "onlySubscribe"
    };

    this.subscribepayload = payload;
  },

  methods: {

    ...mapMutations(["setPlanDeeplinkPath"]),

    toggleSubscribePopup(state) {
      let currentLanguage = this.getCurrentLanguageUrlBase();
      this.$router.push({ name: "Home", params: { lang: currentLanguage } });
    },
    subscribeMetaData(){
      window.document.title = "Ultra Jhakaas Multiple Premium Subscription Plans with Best Offers";
      document.getElementsByTagName('meta')["keywords"].setAttribute('content', "Ultra Jhakaas subscription, Ultra Jhakaas subscription plans, Ultra Jhakaas premium subscription plans, Ultra Jhakaas subscription offer,");
      document.getElementsByTagName('meta')["description"].setAttribute('content', "Avail best offers on Ultra Jhakaas OTT multiple Premium Subscription Plans. Enjoy streaming authentic 3000+ hours of Marathi content. Get your Subscription now!");
      document.querySelector('meta[property="og\\:title"]').setAttribute('content', "Ultra Jhakaas Multiple Premium Subscription Plans with Best Offer");
      document.querySelector('meta[name="twitter\\:title"]').setAttribute('content', "Ultra Jhakaas Multiple Premium Subscription Plans with Best Offer");
      document.querySelector('meta[property="og\\:description"]').setAttribute('content', "Avail best offers on Ultra Jhakaas OTT multiple Premium Subscription Plans. Enjoy streaming authentic 3000+ hours of Marathi content. Get your Subscription now!");
      document.querySelector('meta[name="twitter\\:description"]').setAttribute('content', "Avail best offers on Ultra Jhakaas OTT multiple Premium Subscription Plans. Enjoy streaming authentic 3000+ hours of Marathi content. Get your Subscription now!");
    }
  },

  components: {
    subscribe: () => import(/* webpackChunkName: "subscribe" */ "@/components/Popups/Subscribe.vue"),
  },
   mixins: [Utility]
};
</script>

<style></style>
